import React, { useEffect } from "react";
import Spinner from "../containers/Spinner";
import Papers from "../components/lecturer_research/Papers";
import SeminarArticle from "../components/lecturer_research/SeminarArticle";
import DeTai from "../components/lecturer_research/DeTai";
const LecRe = () => {
  useEffect(()=>{
     document.title = "Công trình nghiên cứu | Khoa Công nghệ thông tin";
   },[]);
  return (
  <>
  <Papers/>
  {/* <SeminarArticle/> */}
  <DeTai/>
  </>
  );
};

export default LecRe;
