import React, { useState, useEffect } from "react";

const MembersLab = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://fit.neu.edu.vn/admin/api/edtech-lab-members?limit=60"
        );
        const result = await response.json();

        // Process data: group, shuffle, and move incomplete data to the bottom
        const processedData = processData(result.results);
        setData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Data processing function
  const processData = (members) => {
    const groups = {};
    const incompleteData = []; // Store rows with missing information

    members.forEach((member) => {
      const groupName =
        member["Group "]?.multi_select?.map((g) => g.name).join(", ") || "N/A";
      const hasCompleteInfo =
        member["Họ tên"]?.rich_text?.[0]?.plain_text &&
        member["Lớp"]?.select?.name;

      if (!hasCompleteInfo) {
        incompleteData.push(member);
      } else {
        if (!groups[groupName]) groups[groupName] = [];
        groups[groupName].push(member);
      }
    });

    // Shuffle groups
    const shuffledGroups = Object.values(groups).sort(() => Math.random() - 0.5);

    // Combine groups and append incomplete data at the end
    return [...shuffledGroups.flat(), ...incompleteData];
  };

  // Pagination calculation
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  // Total pages calculation
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Handle page change
  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="container mt-5">
      {/* Title and pagination */}
      <div className="mb-2">
        <h1 className="text-center mb-1">Thành Viên Edtech Innovation Lab</h1>
        <div className="d-none d-md-flex justify-content-end">
          <nav>
            <ul className="pagination mb-0">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange("prev")}
                  disabled={currentPage === 1}
                >
                  Trước
                </button>
              </li>
              <li className="page-item disabled">
                <span className="page-link">
                  {currentPage}/{totalPages}
                </span>
              </li>
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange("next")}
                  disabled={currentPage === totalPages}
                >
                  Sau
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Table data */}
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th className="text-center" scope="col">
              STT
            </th>
            <th className="text-center" scope="col">
              HỌ VÀ TÊN
            </th>
            <th className="text-center" scope="col">
              LỚP SINH VIÊN
            </th>
            <th className="text-center" scope="col">
              NHÓM NGHIÊN CỨU
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((member, index) => (
            <tr key={index}>
              <td className="text-center">{indexOfFirstRow + index + 1}</td>
              <td>{member["Họ tên"]?.rich_text?.[0]?.plain_text || "-"}</td>
              <td className="text-center">
                {member["Lớp"]?.select?.name || "-"}
              </td>
              <td className="text-center">
                {member["Group "]?.multi_select
                  ?.map((group) => group.name)
                  .join(", ") || "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* No data message */}
      {data.length === 0 && (
        <div className="text-center mt-4">
          <p className="text-muted">Không có dữ liệu.</p>
        </div>
      )}

      {/* Pagination - Centered below table only on mobile */}
      <div className="d-block d-md-none mt-3 text-center">
        <nav>
          <ul className="pagination mb-0 justify-content-end">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
              >
                Trước
              </button>
            </li>
            <li className="page-item disabled">
              <span className="page-link">
                {currentPage}/{totalPages}
              </span>
            </li>
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
              >
                Sau
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MembersLab;
