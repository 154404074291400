import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const PresentDoneLab = () => {
  const [presentations, setPresentations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fit.neu.edu.vn/admin/api/edtech-lab-seminars?limit=100"
        );
        const data = response.data.results
          .filter((item) => item.Done.checkbox)
          .map((item, index) => ({
            stt: index + 1,
            topic: item["Tên chủ đề"].title[0]?.plain_text || "Không có tiêu đề",
            presenter: item["Người trình bày"].rich_text
              .map((text) => text.plain_text)
              .join(", "),
            date: item["Thời gian"].date.start,
            url: item.URL?.url || null,
          }));
        setPresentations(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const totalPages = Math.ceil(presentations.length / itemsPerPage);
  const currentData = presentations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const Pagination = () => (
    <nav className="d-flex justify-content-end">
      <ul className="pagination mb-0">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            Trước
          </button>
        </li>
        <li className="page-item disabled">
          <span className="page-link">
            {currentPage}/{totalPages}
          </span>
        </li>
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          >
            Sau
          </button>
        </li>
      </ul>
    </nav>
  );

  return (
    <div className="container mt-5">
      <div className="mb-2 text-center">
        <h1 className="mb-1">Chủ Đề Đã Trình Bày</h1>
        <div className="d-none d-md-block">
          <Pagination />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-striped mb-1">
          <thead>
            <tr>
              <th className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>STT</th>
              <th className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>Tên Bài Trình Bày</th>
              <th className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>Người Trình Bày</th>
              <th className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>Ngày Trình Bày</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item) => (
              <tr key={item.stt}>
                <td className="text-center align-middle">{item.stt}</td>
                <td className="align-middle">
                  {item.url ? (
                    <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-dark">
                      {item.topic}
                    </a>
                  ) : (
                    item.topic
                  )}
                </td>
                <td className="align-middle">{item.presenter}</td>
                <td className="text-center align-middle">
                  {new Date(item.date).toLocaleDateString("vi-VN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-block d-md-none mt-3 text-center">
        <Pagination />
      </div>
    </div>
  );
};

export default PresentDoneLab;
