import React, { useState, useEffect } from "react";
import axios from "axios";

const ListDisplay = ({ data }) => (
  <div className="row">
    {data.map((item) => (
      <div key={item.id} className="col-12 col-md-6 mb-3">
        <div
          className="list-group-item border border-secondary rounded-3 p-3 hover-border equal-height"
          style={{ transition: "border-color 0.3s, box-shadow 0.3s" }}
        >
          <h6
            className="text-truncate"
            title={item.title}
            style={{ whiteSpace: "pre-wrap", cursor: item.url ? "pointer" : "default" }}
            onClick={() => {
              if (item.url) {
                window.open(item.url, "_blank"); // Mở URL trong tab mới
              }
            }}
          >
            {item.title}
          </h6>
          <p className="mb-1">Tác giả: {item.authors}</p>
          <p className="mb-1">Tạp chí: {item.journal}</p>
          <p className="mb-0">Năm: {item.year}</p>
        </div>
      </div>
    ))}
  </div>
);

const TechProductList = ({ data }) => (
    <div className="row">
      {data.map((item) => (
        <div key={item.id} className="col-12 col-md-6 mb-3">
          <div
            className="list-group-item border border-secondary rounded-3 p-3 hover-border equal-height"
            style={{ transition: "border-color 0.3s, box-shadow 0.3s" }}
          >
            <div className="d-flex align-items-center">
              <img
                src={item.icon || "https://fit.neu.edu.vn/img/LogoFIT.png"}
                alt="Product Icon"
                className="img-fluid me-3"
                style={{ maxHeight: "60px", cursor: item.url ? "pointer" : "default" }}
                onClick={() => {
                  if (item.url) {
                    window.open(item.url, "_blank"); // Mở URL trong tab mới
                  }
                }}
              />
              <div>
                <h6
                  className="text-truncate"
                  title={item.name}
                  style={{ whiteSpace: "pre-wrap", cursor: item.url ? "pointer" : "default" }}
                  onClick={() => {
                    if (item.url) {
                      window.open(item.url, "_blank"); // Mở URL trong tab mới
                    }
                  }}
                >
                  {item.name || "Đang Cập Nhật"}
                </h6>
                <p className="mb-1">Nhóm thực hiện: {item.team || "Đang Cập Nhật"}</p>
                <p className="mb-0">Năm: {item.year || "Đang Cập Nhật"}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
  

const OutputLab = () => {
  const [publications, setPublications] = useState([]);
  const [techProducts, setTechProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("publications");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (selectedCategory === "publications") {
          response = await axios.get("https://fit.neu.edu.vn/admin/api/lecturer-researches?limit=100");
          const filteredData = response.data.results.filter((item) => item.View?.checkbox);
          const mappedData = filteredData.map((item) => ({
            id: item.id,
            title: item.Name.title[0]?.plain_text || "Đang Cập Nhật",
            authors: item["O Authors"]?.multi_select?.map((author) => author.name).join(", ") || "Đang Cập Nhật",
            journal: item.Type?.select?.name || "Đang Cập Nhật",
            year: new Date(item.Date?.date?.start).getFullYear() || "Đang Cập Nhật",
            url: item.UrlDoi?.url || null,
          }));
          setPublications(mappedData);
        } else if (selectedCategory === "techProducts") {
          response = await axios.get("https://fit.neu.edu.vn/admin/api/edtech-lab-products?limit=100");
          const filteredData = response.data.results.filter((item) => item.View?.checkbox);
          const mappedData = filteredData.map((item) => ({
            id: item.id,
            name: item["Tên sản phẩm"]?.title?.[0]?.plain_text || "Đang Cập Nhật",
            icon: item.Icon?.files?.[0]?.file?.url || "",
            url: item.URL?.url || null,
            team: item["Thành viên"]?.rich_text?.[0]?.plain_text || "Đang Cập Nhật",
            year: item["Thời gian"]?.date?.start?.split("-")[0] || "Đang Cập Nhật",
          }));
          setTechProducts(mappedData);
        }
      } catch (error) {
        console.error("Lỗi khi tải dữ liệu:", error);
      }
    };

    fetchData();
  }, [selectedCategory]);

  const data = selectedCategory === "publications" ? publications : techProducts;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const currentData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-1">Công Trình Nghiên Cứu Và Chuyển Giao</h1>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-2">
        <div className="btn-group mb-3 mb-md-0" role="group" aria-label="Output type selector">
          {[
            { label: "Công Bố", value: "publications" },
            { label: "Sản Phẩm", value: "techProducts" },
          ].map((btn) => (
            <button
              key={btn.value}
              type="button"
              className={`btn ${selectedCategory === btn.value ? "btn-primary" : "btn-outline-primary"}`}
              onClick={() => {
                setSelectedCategory(btn.value);
                setCurrentPage(1);
              }}
            >
              {btn.label}
            </button>
          ))}
        </div>

        <nav className="d-none d-md-block">
          <ul className="pagination mb-0">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
                Trước
              </button>
            </li>
            <li className="page-item disabled">
              <span className="page-link">
                {currentPage}/{totalPages}
              </span>
            </li>
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>
                Sau
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {selectedCategory === "publications" ? (
        <ListDisplay data={currentData} />
      ) : (
        <TechProductList data={currentData} />
      )}

      <nav className="d-block d-md-none mt-3">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
              Trước
            </button>
          </li>
          <li className="page-item disabled">
            <span className="page-link">
              {currentPage}/{totalPages}
            </span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>
              Sau
            </button>
          </li>
        </ul>
      </nav>

      <style>{`
        .hover-border {
          border-color: #dee2e6;
        }
        .hover-border:hover {
          border-color: #0d6efd;
          box-shadow: 0 0 10px rgba(13, 110, 253, 0.5);
        }
        .equal-height {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
};

export default OutputLab;
