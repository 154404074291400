import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FaUserCheck} from "react-icons/fa";
import {doc, getFirestore, getDoc} from "firebase/firestore";
import app from "../../firebase";

export default function AttendeesModal({roomID, roomUsers = []}) {
  const [members, setMembers] = useState([]);
  const [show, setShow] = useState(false);
  const getNotAttendees = () => {
    const attendees = roomUsers?.map(user => user?.email?.split('@')[0]) || [];
    return members?.filter(member => !attendees?.includes(member.studentCode)) || [];
  }

  useEffect(() => {
    const getMembers = async () => {
      const firestore = getFirestore(app);
      const roomRef = doc(firestore, "rooms", roomID);
      const roomSnap = await getDoc(roomRef);
      if (roomSnap.exists()) {
        setMembers(roomSnap.data()?.members || []);
      } else {
        console.log("No such document!");
      }
    }
    
    if (show) {
      getMembers();
    }
  }, [show]);
  
  return (
    <>
      <button className="btn btn-primary ms-2 me-2 hide-expand" onClick={() => setShow(true)}>
        <FaUserCheck size={24}/>
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thành viên chưa tham gia</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "50vh",
            overflowX: "auto",
          }}
        >
          {members?.length > 0 ? <ul>
            {getNotAttendees().length > 0 ? getNotAttendees().map((member, index) => (
              <li key={index}>{member?.studentCode} - {member?.fullName}</li>
            )) : <p>Tất cả thành viên đã tham gia!</p>}
          </ul> : (
            <p>Chưa có danh sách thành viên, vui lòng cập nhật trong cài đặt!</p>
          )}
          
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary ms-2 me-2 hide-expand" onClick={() => setShow(false)}>
            Đóng
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}